import React, { createContext, useContext, useLayoutEffect, useState, useEffect } from "react";
import { createClient, fetchExchange, cacheExchange } from "@urql/core";
import { ethers } from "ethers";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  RocksAddress,
  farmingAddress,
  stayAddress,
  swapperAddress,
  marketplaceAddress,
  zapAddress,
  referralAddress,
  queryUrl,
  RocksABI,
  farmingABI,
  stayABI,
  swapperABI,
  marketplaceABI,
  zapABI,
  referralABI,
  usdcAddress,
  BNB,
  routerABI,
  router,
  RPC,
  wbnb,
  lpAddress,
  myusdAddress,
  oldLpAddress,
  printerAddress,
  printerABI,
  discountedNftAddress,
  discountedABI,
  coinGeckoApi,
  oldprinterAddress,
  oldPrinterABI,
  queryUrlPrinter,
} from "../utils/constants";
import { useAccount } from "wagmi";
import { zeroAddress } from "viem";
import { useEthereum } from "@particle-network/auth-core-modal";
import { getLatestAuthType, isSocialAuthType } from "@particle-network/auth-core";

const ThemeContext = createContext();
const NfstayContext = createContext();

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const useNfstay = () => {
  const context = useContext(NfstayContext);
  if (!context) {
    throw new Error("useNfstay must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("");

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    document.body.setAttribute("data-theme", newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useLayoutEffect(() => {
    const storedTheme = localStorage.getItem("theme") || "light";
    setTheme(storedTheme);
    document.body.setAttribute("data-theme", storedTheme);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const { ethereum } = window;

export const NfstayProvider = ({ children }) => {
  const _1ether = ethers.utils.parseEther("1");
  const { provider: particleProvider } = useEthereum();
  const { address: account } = useAccount();
  const location = useLocation(); // Get the current location

  const [stayPrice, setStayPrice] = useState(0);
  const [percentChange, setPercentChange] = useState({
    price_change_24h: 0,
    price_change_percentage_24h: 0,
    lineColor: "#A0A3AA",
  });

  const [chartData, setChartData] = useState([]);

  const [discountedPrice, setDiscountedPrice] = useState("");
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [usdcApprovalDiscounted, setUsdcApprovalToDiscounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [printerHistory, setPrinterHistory] = useState([]);
  const [farmHistory, setFarmHistory] = useState([]);

  const [stakedIds, setStakedIds] = useState([]);
  const [burnHistory, setBurnHistory] = useState([]);
  const [farmingRewardPerSecond, setFarmingRewardPerSecond] = useState(0);

  const [totalLpStaked, setTotalLpStaked] = useState(0);
  // const [approval, setApproval] = useState(false);
  const [marketplaceNfts, setMarketplaceNfts] = useState([]);
  const [usdcApproval, setUSDCApproval] = useState(false);
  const [stayApproval, setStayApproval] = useState(false);
  const [myusdApproval, setmyusdApproval] = useState(false);
  const [USDCApprovaltoRocks, setUSDCApprovaltoRocks] = useState(false);
  const [USDCApprovaltoRouter, setUSDCApprovaltoRouter] = useState(false);
  const [oldLpApproval, setoldLpApproval] = useState(false);
  const [marketSalesVolume, setMarketSalesVolume] = useState([]);
  const [myNfts, setMyNfts] = useState([]);
  // const [chosenTokens, setChosenTokens] = useState(null);
  // const [amounts, setAmounts] = useState();
  // const [farmingTotalRewards, setFarmingTotalRewards] = useState(0);
  // const [poolEnd, setFarmingEnd] = useState(0);
  const [printerRewards, setPrinterRewards] = useState(0);
  const [dashboardDetails, setDasboardDetails] = useState({
    noOfNFTs: 0,
    // tvl: 0,
    noOfStay: 0,
    totalPaid: 0,
  });
  const [farmingPendingShare, setFarmingPendingShare] = useState(0);
  // const [farmingRewardPerSecond, setFarmingRewardPerSecond] = useState(0);
  const [userDetails, setUserDetails] = useState({
    amount: 0,
    pendingRewards: 0,
  });
  const [zapperApproval, setApprovalZapper] = useState(false);

  const [stayRate, setStayRate] = useState(0);
  const [referralDetails, setReferralDetails] = useState({
    noOfRefrees: 0,
    noOfrockSold: 0,
    rewardEarned: 0,
  });
  const [porfolioDetails, setPortfolioDetails] = useState({
    stayTokens: 0,
    rocksTokens: 0,
    lpTokens: 0,
  });
  const [oldAssets, setOldAsset] = useState({
    oldLpBal: 0,
    myusdBal: 0,
  });
  const [lpRate, setLpRate] = useState(0);
  const [printerDetails, setPrinterDetails] = useState({
    currentLevel: 0,
    isBoosted: false,
    stakedNFTs: 0,
    stakedSTAY: 0,
    pendingRewards: 0,
    isLock: false,
    lockingPeriod: 0,
    badges: [],
  });

  const [oldPrinterDetails, setOldPrinterDetails] = useState({
    currentLevel: 0,
    isBoosted: false,
    stakedNFTs: 0,
    stakedSTAY: 0,
    pendingRewards: 0,
    isLock: false,
    lockingPeriod: 0,
    badges: [],
  });

  const [assetPrices, setAssetPrices] = useState({
    stayPrice: 0,
    staySupply: 0,
    lpPrice: 0,
    rockSupply: 0,
    lpSupply: 0,
    rockPrice: 0,
  });
  // TODO: chris has asked to divide the apr by two
  const [aprs] = useState({
    NormalAPR: [35, 40, 41, 42, 43, 44, 45, 46, 47, 48].map(value => value / 2),
    boostedAPR: [40, 41, 42, 43, 44, 45, 46, 47, 48, 50].map(value => value / 2),
  });
  const [badgePrices, setBadgePrices] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [floorPrice, setFloorPrice] = useState(0);
  const [volume, setVolume] = useState(0);
  const [boosterAmounts, setBoosterAmounts] = useState([]);

  ///getting all contracts

  const getMarketplaceContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(marketplaceAddress, marketplaceABI, signer);
      return contract;
    }
    contract = new ethers.Contract(marketplaceAddress, marketplaceABI, provider);
    return contract;
  };

  const getStayContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(stayAddress, stayABI, signer);
      return contract;
    }
    contract = new ethers.Contract(stayAddress, stayABI, provider);
    return contract;
  };

  const getPrinterContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(printerAddress, printerABI, signer);
      return contract;
    }
    contract = new ethers.Contract(printerAddress, printerABI, provider);
    return contract;
  };

  const getOldPrinterContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(oldprinterAddress, oldPrinterABI, signer);
      return contract;
    }
    contract = new ethers.Contract(oldprinterAddress, oldPrinterABI, provider);
    return contract;
  };

  const getLPContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(lpAddress, stayABI, signer);
      return contract;
    }
    contract = new ethers.Contract(lpAddress, stayABI, provider);
    return contract;
  };

  const getmyUSDContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(myusdAddress, stayABI, signer);
      return contract;
    }
    contract = new ethers.Contract(myusdAddress, stayABI, provider);
    return contract;
  };

  const getoldLpContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(oldLpAddress, stayABI, signer);
      return contract;
    }
    contract = new ethers.Contract(oldLpAddress, stayABI, provider);
    return contract;
  };

  const getUSDCContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(usdcAddress, stayABI, signer);
      return contract;
    }
    contract = new ethers.Contract(usdcAddress, stayABI, provider);
    return contract;
  };

  const getFarmingContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(farmingAddress, farmingABI, signer);
      return contract;
    }
    contract = new ethers.Contract(farmingAddress, farmingABI, provider);
    return contract;
  };

  const getMyRocksContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(RocksAddress, RocksABI, signer);
      return contract;
    }
    contract = new ethers.Contract(RocksAddress, RocksABI, provider);
    return contract;
  };

  const getReferralContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(referralAddress, referralABI, signer);
      return contract;
    }
    contract = new ethers.Contract(referralAddress, referralABI, provider);
    return contract;
  };

  const getSwapperContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(swapperAddress, swapperABI, signer);
      return contract;
    }
    contract = new ethers.Contract(swapperAddress, swapperABI, provider);
    return contract;
  };

  const getRouterContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(router, routerABI, signer);
      return contract;
    }
    contract = new ethers.Contract(router, routerABI, provider);
    return contract;
  };

  const getDiscountedContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      contract = new ethers.Contract(discountedNftAddress, discountedABI, signer);
      return contract;
    }
    contract = new ethers.Contract(discountedNftAddress, discountedABI, provider);
    return contract;
  };

  const getZapContract = (signer = false) => {
    let provider = new ethers.providers.JsonRpcProvider(RPC);
    let contract;
    if (signer) {
      let ethersProvider;
      if (isSocialAuthType(getLatestAuthType())) {
        ethersProvider = new ethers.providers.Web3Provider(particleProvider);
      } else {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      }

      if (!ethersProvider) {
        console.log("ethersProvider not initialized yet");
        return;
      }

      const signer = ethersProvider.getSigner();
      let contract = new ethers.Contract(zapAddress, zapABI, signer);
      return contract;
    }
    contract = new ethers.Contract(zapAddress, zapABI, provider);
    return contract;
  };

  const client = createClient({
    url: queryUrl,
    exchanges: [cacheExchange, fetchExchange],
  });

  const clientPrinter = createClient({
    url: queryUrlPrinter,
    exchanges: [cacheExchange, fetchExchange],
  });

  // *****************ASMRGRAPH**********************

  // Example usage:

  const getBurnHistory = async () => {
    try {
      const burnsQuery = `
      query getBurnHistory {
        transfers(
          where: {
            to: "0x0000000000000000000000000000000000000000"
          }
          orderBy: blockTimestamp
          orderDirection: desc
        ) {
          id
          value
          blockTimestamp
          from
        }
			}`;

      const { data } = await client.query(burnsQuery).toPromise();

      let history = data?.transfers?.map(item => ({
        id: item.id,
        value: ethers.utils.formatEther(item.value),
        date: new Date(parseInt(item.blockTimestamp) * 1000).toLocaleString(),
        source: item.from,
      }));

      setBurnHistory(history);
    } catch (err) {
      console.log(err);
    }
  };

  const getFarmHistory = async () => {
    try {
      const rewards = `
			query GetRewardPaids($account: String!) {
				rewardsClaimeds(
          where: { by: $account }
          orderBy: blockTimestamp
          orderDirection: desc
        ) {
          id
          amount
          blockTimestamp
          blockNumber
        }
			}`;

      const { data } = await client.query(rewards, { account }).toPromise();

      const convertedData = data?.rewardsClaimeds?.map(item => ({
        id: item.id,
        amountEther: ethers.utils.formatEther(item.amount),
        date: new Date(parseInt(item.blockTimestamp) * 1000).toLocaleString(),
        blockNumber: item.blockNumber,
        source: "Farm",
      }));

      setFarmHistory(convertedData);
    } catch (err) {
      console.log(err);
    }
  };

  const getBoughtItems = async () => {
    const batchSize = 100; // Number of events to fetch per request
    let allEvents = [];
    let hasMore = true;
    let skip = 0;

    while (hasMore) {
      // Define the GraphQL query to fetch rewardsClaimed events in batches
      const queryQl = `
        {
          itemBoughts(skip: ${skip}){
          currencyAddress
          price
        }
        }
      `;

      try {
        // Make the POST request using axios
        const response = await axios.post(queryUrl, {
          query: queryQl,
        });

        // Extract the data from the response
        const _100Events = response.data?.data?.itemBoughts;

        // If no more events are returned, exit the loop
        if (!_100Events || _100Events.length === 0) {
          hasMore = false;
        } else {
          if (_100Events.length < batchSize) {
            hasMore = false; // Less than batchSize means this was the last batch
          }
          allEvents = [...allEvents, ..._100Events];

          // Increase skip value for the next batch
          skip += batchSize;
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching user rewards claimed events:", error);
        hasMore = false; // Stop fetching on error
      }
    }

    // console.log('allEvents:', { allEvents });
    // Calculate the sum of all rewards
    // console.log(allEvents)
    const prices = await Promise.all(
      allEvents.map(async item => {
        const currencyAddress = item.currencyAddress;
        let price = item.price;

        if (currencyAddress.toLowerCase() === BNB?.toLowerCase()) {
          const res = await getRouterContract().getAmountsOut(price, [wbnb, usdcAddress]);
          price = parseFloat(ethers.utils.formatEther(res[1]._hex));
        } else if (currencyAddress.toLowerCase() === stayAddress.toLowerCase()) {
          const res = await getRouterContract().getAmountsOut(price, [stayAddress, usdcAddress]);
          price = parseFloat(ethers.utils.formatEther(res[1]._hex));
        } else {
          price = parseFloat(ethers.utils.formatEther(price));
        }

        return price;
      }),
    );
    setMarketSalesVolume(prices);
  };

  const getPrinterHistory = async () => {
    try {
      const rewards = `
			query GetRewardPaids($account: String!) {
				claimRewards(
          where: { by: $account }
          orderBy: blockTimestamp
          orderDirection: desc
        ) {
          id
          amount
          blockTimestamp
          blockNumber
        }
			}`;

      const { data } = await clientPrinter.query(rewards, { account }).toPromise();
      const convertedData = data?.claimRewards?.map(item => ({
        id: item.id,
        amountEther: ethers.utils.formatEther(item.amount),
        date: new Date(parseInt(item.blockTimestamp) * 1000).toLocaleString(),
        blockNumber: item.blockNumber,
        source: "Printer",
      }));
      setPrinterHistory(convertedData);
    } catch (err) {
      console.log(err);
    }
  };

  const getFloorPrice = async () => {
    try {
      let floorPrice = null; // Initialize with null to handle the comparison logic later.
      let tvolume = 0;
      let ownedNfts = await getAllOwnedNfts(marketplaceAddress);

      if (!ownedNfts || ownedNfts.length === 0) {
        return;
      }

      const prices = await Promise.all(
        ownedNfts.map(async nft => {
          const details = await getMarketplaceContract().getListing(nft);
          let price;

          if (details.currency?.toLowerCase() === BNB?.toLowerCase()) {
            const res = await getRouterContract().getAmountsOut(details.price._hex, [
              wbnb,
              usdcAddress,
            ]);
            price = parseFloat(ethers.utils.formatEther(res[1]._hex));
          } else if (details.currency.toLowerCase() === stayAddress.toLowerCase()) {
            const res = await getRouterContract().getAmountsOut(details.price._hex, [
              stayAddress,
              usdcAddress,
            ]);
            price = parseFloat(ethers.utils.formatEther(res[1]._hex));
          } else {
            price = parseFloat(ethers.utils.formatEther(details.price._hex));
          }

          return price;
        }),
      );

      prices.forEach(price => {
        if (floorPrice === null || price < floorPrice) {
          floorPrice = price;
        }
        tvolume += price;
      });

      setFloorPrice(floorPrice.toFixed(2));
      setVolume(tvolume.toFixed(2));
    } catch (err) {
      console.log(err);
    }
  };

  const listItem = async (Id, address, price) => {
    const list = await getMarketplaceContract(true).listItem(
      Id,
      address,
      ethers.utils.parseEther(price),
    );
    await list.wait();

    await loadMyNfts();
    await checkForApproval(Id);

    await loadListedItems();
    await getPrinterDetails();
  };

  const checkForApproval = async Id => {
    try {
      const checkForApproval = await getMyRocksContract().getApproved(Id);
      if (checkForApproval.toLowerCase() === marketplaceAddress.toLowerCase()) {
        return true;
      }
      return false;
    } catch (err) {
      // throw new Error(err.reason);
      console.log(err);
    }
  };

  const checkUsdcTokenApproval = async () => {
    try {
      const checkForApproval = await getUSDCContract().allowance(account, marketplaceAddress);

      if (parseInt(checkForApproval._hex) > 0) {
        setUSDCApproval(true);
      } else {
        setUSDCApproval(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkUsdcTokenApprovaltoRocks = async () => {
    try {
      const checkForApproval = await getUSDCContract().allowance(account, referralAddress);

      if (parseInt(checkForApproval._hex) > 0) {
        setUSDCApprovaltoRocks(true);
        return true;
      } else {
        setUSDCApprovaltoRocks(false);

        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkUsdcTokenApprovaltoRouter = async () => {
    try {
      const checkForApproval = await getUSDCContract().allowance(account, router);
      if (parseInt(checkForApproval._hex) > 0) {
        setUSDCApprovaltoRouter(true);
      } else {
        setUSDCApprovaltoRouter(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkStayTokenApproval = async () => {
    try {
      const checkForApproval = await getStayContract().allowance(account, marketplaceAddress);
      if (parseInt(checkForApproval._hex) > 0) {
        setStayApproval(true);
      } else {
        setStayApproval(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const oldAssetBalance = async () => {
    try {
      const myusdBal = await getmyUSDContract().balanceOf(account);
      const oldLpBal = await getoldLpContract().balanceOf(account);
      setOldAsset({
        myusdBal: Number(ethers.utils.formatEther(myusdBal)).toFixed(2),
        oldLpBal: Number(ethers.utils.formatEther(oldLpBal)).toFixed(2),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const checkMyusdTokenApproval = async () => {
    try {
      const checkForApproval = await getmyUSDContract().allowance(account, swapperAddress);

      if (parseInt(checkForApproval._hex) > 0) {
        setmyusdApproval(true);
      } else {
        setmyusdApproval(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkoldLpApproval = async () => {
    try {
      const checkForApproval = await getoldLpContract().allowance(account, swapperAddress);

      if (parseInt(checkForApproval._hex) > 0) {
        setoldLpApproval(true);
      } else {
        setoldLpApproval(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const approveToMarketplace = async Id => {
    try {
      const getApproval = await getMyRocksContract(true).approve(marketplaceAddress, Id);
      await getApproval.wait();
      await checkForApproval(Id);
    } catch (err) {
      throw new Error(err.reason);
    }
  };

  const buyItem = async (Id, currency, price) => {
    try {
      let item;
      if (currency !== BNB) {
        item = await getMarketplaceContract(true)?.buyItem(Id);
      } else {
        price = parseInt(price) + price * 0.1;
        item = await getMarketplaceContract(true)?.buyItem(Id, {
          value: `${price}`,
        });
      }
      await item.wait();
      await loadListedItems();
      await loadMyNfts();
      await getBoughtItems();
    } catch (err) {
      console.log(err);
      throw new Error(err.reason);
      // throw
    }
  };

  const approveswapperToken = async currency => {
    let getApproval;
    let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    if (currency.toLowerCase() === myusdAddress.toLowerCase()) {
      getApproval = await getmyUSDContract(true).approve(swapperAddress, value);
    } else {
      getApproval = await getoldLpContract(true).approve(swapperAddress, value);
    }
    await getApproval.wait();
    await checkMyusdTokenApproval();
    await checkoldLpApproval();
  };

  const approveUSDCtoRocks = async () => {
    let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    const getApproval = await getUSDCContract(true).approve(referralAddress, value);
    await getApproval.wait();
    await checkUsdcTokenApprovaltoRocks();
  };

  const approveUSDCtoRouter = async () => {
    let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    const getApproval = await getUSDCContract(true).approve(router, value);
    await getApproval.wait();
    await checkUsdcTokenApprovaltoRouter();
  };

  const approveToken = async currency => {
    try {
      let getApproval;
      let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      if (currency.toLowerCase() === stayAddress.toLowerCase()) {
        getApproval = await getStayContract(true).approve(marketplaceAddress, value);
      } else {
        getApproval = await getUSDCContract(true).approve(marketplaceAddress, value);
      }
      await getApproval.wait();
      await checkStayTokenApproval();
      await checkUsdcTokenApproval();
    } catch (err) {
      console.log(err);
    }
  };

  const updateItem = async (Id, address, price) => {
    try {
      const item = await getMarketplaceContract(true)?.updateListing(Id, address, price);
      await item.wait();
      // setAmounts("");
      // setChosenTokens(null);
      await loadListedItems();
    } catch (err) {
      console.log(err);
    }
  };

  const cancelItem = async Id => {
    try {
      const item = await getMarketplaceContract(true)?.cancelListing(Id);
      await item.wait();
      await loadListedItems();
      await loadMyNfts();
      await getPrinterDetails();
    } catch (err) {
      throw new Error(err.reason);
    }
  };

  const getAllOwnedNfts = async address => {
    try {
      const listings = await getMyRocksContract().walletOfOwner(address);

      let itemInfo = [];

      for (let i = 0; i < listings.length; i++) {
        itemInfo[i] = parseInt(listings[i]?._hex);
      }

      return itemInfo;
    } catch (err) {
      console.log(err);
    }
  };

  const getDashboardValues = async () => {
    try {
      const nfts = await getMyRocksContract().walletOfOwner(printerAddress);
      let stayBal = await getStayContract().balanceOf(printerAddress);
      stayBal = Number(ethers.utils.formatEther(stayBal));
      let farmingRewards = await getFarmingContract().getGeneratedRewards();
      farmingRewards = Number(ethers.utils.formatEther(farmingRewards));

      let printerRewards = await getPrinterContract().getGeneratedRewards();
      printerRewards = Number(ethers.utils.formatEther(printerRewards));
      setDasboardDetails({
        noOfNFTs: nfts.length,
        noOfStay: stayBal,
        totalPaid: farmingRewards + printerRewards,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const loadMyNfts = async () => {
    const baseUri =
      "https://white-recent-porpoise-462.mypinata.cloud/ipfs/QmTCK6aXu6DwnJQuVAH4GZPtWjiK8sqopokVEvvaYXHPww/";

    let ListedItems = [];

    let ownedNfts = await getAllOwnedNfts(account);
    for (let indx = 0; indx < ownedNfts?.length; indx++) {
      // const uri = await getMyRocksContract().tokenURI(ownedNfts[indx]);
      const uri = baseUri.concat(`${ownedNfts[indx]}.json`);

      const response = await fetch(uri);
      const metadata = await response.json();

      let item = {
        id: ownedNfts[indx],
        name: metadata.name,
        description: metadata.description,
        image: metadata.image,
      };
      ListedItems.push(item);
    }

    setMyNfts(ListedItems);
  };

  // const hexToNumber = hexString => parseInt(hexString, 16);

  // const sortData = marketplaceNfts => {
  //   let data = marketplaceNfts.sort(async (a, b) => {
  //     let priceA = a.price;
  //     let priceB = b.price;

  //     if (a.currency?.toLowerCase() === BNB?.toLowerCase()) {
  //       const resA = await getRouterContract().getAmountsOut(priceA, [wbnb, usdcAddress]);
  //       priceA = parseFloat(ethers.utils.formatEther(resA[1]._hex));
  //     } else if (a.currency?.toLowerCase() === stayAddress?.toLowerCase()) {
  //       const resA = await getRouterContract().getAmountsOut(priceA, [stayAddress, usdcAddress]);
  //       priceA = parseFloat(ethers.utils.formatEther(resA[1]._hex));

  //     } else {
  //       priceA = parseFloat(ethers.utils.formatEther(priceA));

  //     }

  //     if (b.currency?.toLowerCase() === BNB?.toLowerCase()) {
  //       const resB = await getRouterContract().getAmountsOut(priceB, [wbnb, usdcAddress]);
  //       priceB = parseFloat(ethers.utils.formatEther(resB[1]._hex));

  //     } else if (b.currency?.toLowerCase() === stayAddress?.toLowerCase()) {
  //       const resB = await getRouterContract().getAmountsOut(priceB, [stayAddress, usdcAddress]);
  //       priceB = parseFloat(ethers.utils.formatEther(resB[1]._hex));

  //     } else {
  //       priceB = parseFloat(ethers.utils.formatEther(priceB));

  //     }

  //     return hexToNumber(priceA) - hexToNumber(priceB);
  //   });

  // };

  const loadListedItems = async (sort = "lowtohigh") => {
    try {
      const baseUri =
        "https://white-recent-porpoise-462.mypinata.cloud/ipfs/QmTCK6aXu6DwnJQuVAH4GZPtWjiK8sqopokVEvvaYXHPww/";

      let ListedItems = [];
      // let ListedItems2 = [];

      let ownedNfts = await getAllOwnedNfts(marketplaceAddress);
      for (let indx = 0; indx < ownedNfts?.length; indx++) {
        const uri = baseUri.concat(`${ownedNfts[indx]}.json`);
        const info = await getMarketplaceContract().getListing(ownedNfts[indx]);

        const response = await fetch(uri);
        const metadata = await response.json();

        let item = {
          id: ownedNfts[indx],
          name: metadata.name,
          image: metadata.image,
          currency: info.currency,
          price: info.price._hex,
          seller: info.seller,
        };
        ListedItems.push(item);
        // let item2 = {
        //   id: ownedNfts[indx],

        //   currency: info.currency,
        //   price: info.price._hex,
        // };
        // ListedItems2.push(item2);
      }
      // sortData(ListedItems2);
      // console.log("sort before", ListedItems2);
      setMarketplaceNfts(ListedItems);
    } catch (err) {
      console.log(err);
    }
  };

  // *****************ASMRFARMING**********************

  const Farming_RewardPerSecond = async () => {
    try {
      const perSec = await getFarmingContract().getStayPerSecond();
      setFarmingRewardPerSecond(ethers.utils.formatEther(perSec));
    } catch (err) {
      console.error(err);
    }
  };

  const Farming_getTotalLpStaked = async () => {
    let bal = await getLPContract().balanceOf(farmingAddress);
    bal = Number(ethers.utils.formatEther(bal._hex)).toFixed(2);
    setTotalLpStaked(bal);
  };

  const Farming_stakeLps = async amount => {
    amount = ethers.utils.parseEther(`${amount}`);
    const stake = await getFarmingContract(true).stakeLPs(amount);
    await stake.wait();
    await Farming_getUserInfo();
  };

  const checkTokenApprovalToZapper = async currency => {
    try {
      let checkForApproval;
      let isApproved;
      if (currency === "STAY") {
        checkForApproval = await getStayContract().allowance(account, zapAddress);
        if (parseInt(checkForApproval._hex) > 0) {
          isApproved = true;
        } else {
          isApproved = false;
        }
      } else if (currency === "USDC") {
        checkForApproval = await getUSDCContract().allowance(account, zapAddress);
        if (parseInt(checkForApproval._hex) > 0) {
          isApproved = true;
        } else {
          isApproved = false;
        }
      } else {
        checkForApproval = await getLPContract().allowance(account, farmingAddress);
        if (parseInt(checkForApproval._hex) > 0) {
          isApproved = true;
        } else {
          isApproved = false;
        }
      }
      setApprovalZapper(isApproved);
      return isApproved;
    } catch (err) {
      console.log(err);
    }
  };

  const approveToZapper = async currency => {
    let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    let getApproval;
    if (currency === "STAY") {
      getApproval = await getStayContract(true).approve(zapAddress, value);
    } else if (currency === "USDC") {
      getApproval = await getUSDCContract(true).approve(zapAddress, value);
    } else {
      getApproval = await getLPContract(true).approve(farmingAddress, value);
    }
    await getApproval.wait();
    await checkTokenApprovalToZapper(currency);
  };

  const Farming_getPendingShare = async () => {
    try {
      const share = await getFarmingContract().earned(account);
      setFarmingPendingShare(ethers.utils.formatEther(share));
    } catch (err) {
      console.error(err);
    }
  };

  const Farming_ClaimRewards = async () => {
    const withdraw = await getFarmingContract(true).claimRewards();
    const tx = await withdraw.wait();
    await Farming_getPendingShare();
    await Farming_getUserInfo();
    await getDashboardValues();
    const events = await getFarmingContract().queryFilter("RewardsClaimed", tx.blockNumber);
    return ethers.utils.formatEther(events[0].args[1]._hex);
  };

  const Farming_Unstake = async value => {
    const unstake = await getFarmingContract(true).unstakeLPs(
      ethers.utils.parseEther(String(value)),
    );
    await unstake.wait();
    await Farming_getPendingShare();
    await Farming_getUserInfo();
  };

  const Farming_getUserInfo = async () => {
    try {
      const userInfo = await getFarmingContract().userInfo(account);
      setUserDetails({
        amount: ethers.utils.formatEther(userInfo.amount),
        pendingRewards: ethers.utils.formatEther(userInfo.pendingRewards),
      });

      await getFarmHistory();
      await Farming_getTotalLpStaked();
    } catch (err) {
      console.error(err);
    }
  };

  // *****************ASMRSWAPPER**********************

  const Swapper_swap = async (item, amount) => {
    const swap = await getSwapperContract(true).swap(item, ethers.utils.parseEther(amount));
    await swap.wait();
    await oldAssetBalance();
  };

  const Swapper_getStayRate = async () => {
    try {
      const rate = await getSwapperContract().getStayRate();

      setStayRate(ethers.utils.formatEther(rate));
    } catch (err) {
      console.error(err);
    }
  };

  const Swapper_getLpRate = async () => {
    try {
      const rate = await getSwapperContract().getLpRate();

      setLpRate(ethers.utils.formatEther(rate));
    } catch (err) {
      console.error(err);
    }
  };

  // *****************ASMRREFERRAL**********************

  const Referral_buyNFT = async quantity => {
    const queryParams = new URLSearchParams(location.search); // Parse the query string
    let referralCode = queryParams.get("referralcode"); // Get the referral code
    const buy = await getReferralContract(true).buyNFT(
      quantity,
      referralCode == null ? zeroAddress : referralCode,
    );
    await buy.wait();
    await loadMyNfts();
    await getPortfolioValues();
  };

  const Referral_getDetails = async () => {
    try {
      const res = await getReferralContract().getDetails(account);

      setReferralDetails({
        noOfRefrees: Number(res.noOfRefrees),
        rewardEarned: Number(ethers.utils.formatEther(res.rewardEarned)),
        noOfrockSold: Number(res.noOfrockSold),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getPortfolioValues = async () => {
    try {
      const stayBal = await getStayContract().balanceOf(account);
      const lpBal = await getLPContract().balanceOf(account);
      const rockBal = await getMyRocksContract().walletOfOwner(account);

      setPortfolioDetails({
        stayTokens: Number(ethers.utils.formatEther(stayBal)),
        rocksTokens: rockBal.length,
        lpTokens: Number(ethers.utils.formatEther(lpBal)),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getAssetPrices = async () => {
    try {
      let staysupply = await getStayContract().totalSupply();
      staysupply = Number(ethers.utils.formatEther(staysupply));
      let stayprice = await getRouterContract().getAmountsOut(_1ether, [stayAddress, usdcAddress]);
      stayprice = Number(ethers.utils.formatEther(stayprice[1]));
      let lpsupply = await getLPContract().totalSupply();
      lpsupply = Number(ethers.utils.formatEther(lpsupply));
      let tusdc = await getUSDCContract().balanceOf(lpAddress);
      tusdc = Number(ethers.utils.formatEther(tusdc));
      let tstay = await getStayContract().balanceOf(lpAddress);
      tstay = Number(ethers.utils.formatEther(tstay)) * stayprice;
      const rocksupply = Number(await getMyRocksContract().totalSupply());
      const lpPrice = (tusdc + tstay) / lpsupply;
      let rockprice = await getMyRocksContract().price();
      rockprice = Number(ethers.utils.formatEther(rockprice));
      setAssetPrices({
        stayPrice: stayprice,
        staySupply: staysupply,
        lpPrice: lpPrice,
        lpSupply: lpsupply,
        rockSupply: rocksupply,
        rockPrice: rockprice,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // *****************ASMRROUTER**********************

  const Router_getStayPrice = async (bnb = false) => {
    try {
      let path = [usdcAddress, stayAddress];
      if (bnb) {
        path = [wbnb, usdcAddress, stayAddress];
        const price = await getRouterContract().getAmountsOut(_1ether, path);
        setStayPrice(Number(ethers.utils.formatEther(`${price[2]._hex}`)).toFixed(2));
      } else {
        const price = await getRouterContract().getAmountsOut(_1ether, path);
        setStayPrice(Number(ethers.utils.formatEther(`${price[1]._hex}`)).toFixed(2));
      }
    } catch (err) {
      console.error(err);
    }
  };

  // zapper

  const getbalance = async currency => {
    try {
      let bal;
      if (currency === BNB) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        bal = await provider.getBalance(account);
      } else if (currency === stayAddress) {
        bal = await getStayContract().balanceOf(account);
      } else if (currency === usdcAddress) {
        bal = await getUSDCContract().balanceOf(account);
      } else {
        bal = await getLPContract().balanceOf(account);
      }
      bal = ethers.utils.formatEther(`${bal._hex}`);

      return bal;
    } catch (err) {
      console.log(err);
    }
  };

  const zapper_swapping = async (currency, amount) => {
    let res;

    if (currency === "BNB") {
      res = await getZapContract(true).zapIn(lpAddress, account, {
        value: ethers.utils.parseEther(String(amount)),
      });
    } else {
      currency = currency === "STAY" ? stayAddress : usdcAddress;

      res = await getZapContract(true).zapInToken(
        currency,
        ethers.utils.parseEther(String(amount)),
        lpAddress,
        account,
      );
    }
    const tx = await res?.wait();
    const events = await getZapContract().queryFilter("LiquidityAdded", tx?.blockNumber);

    return ethers.utils.formatEther(events[0].args[1]._hex);
  };

  // *****************Printer**********************

  const getBoosterAmounts = async () => {
    try {
      const details = await getPrinterContract().getAllBoosters();

      setBoosterAmounts(details.map(amount => Number(ethers.utils.formatEther(amount))));
    } catch (err) {
      console.log(err);
    }
  };

  //TODO:chris has asked to divide the pending rewards by 2
  const getPrinterDetails = async () => {
    try {
      const details = await getPrinterContract().getDetails(account);
      setPrinterDetails({
        currentLevel: Number(details.currentLevel),
        isBoosted: details.isBoosted,
        stakedNFTs: Number(details.stakedNFTs),
        isLock: Number(details.lockingPeriod) > Math.floor(Date.now() / 1000),
        lockingPeriod: Number(details.lockingPeriod) * 1000,
        stakedSTAY: Number(ethers.utils.formatEther(details.stakedSTAY)),
        pendingRewards: Number(ethers.utils.formatEther(details.pendingRewards)) / 2,
        badges: details.badges.map(badge => Number(badge)),
      });
      await getStakedIdsPrinter();
      await getPrinterHistory();
    } catch (err) {
      console.log(err);
    }
  };

  const getStakedIdsPrinter = async () => {
    try {
      const baseUri =
        "https://white-recent-porpoise-462.mypinata.cloud/ipfs/QmTCK6aXu6DwnJQuVAH4GZPtWjiK8sqopokVEvvaYXHPww/";

      const details = await getPrinterContract().getStakedIds(account);

      let itemInfo = [];
      let stakedIdsDetails = [];

      for (let i = 0; i < details.length; i++) {
        itemInfo[i] = parseInt(details[i]?._hex);
      }
      for (let indx = 0; indx < itemInfo?.length; indx++) {
        // const uri = await getMyRocksContract().tokenURI(ownedNfts[indx]);
        const uri = baseUri.concat(`${itemInfo[indx]}.json`);

        const response = await fetch(uri);
        const metadata = await response.json();

        let item = {
          id: itemInfo[indx],
          name: metadata.name,
          description: metadata.description,
          image: metadata.image,
        };
        stakedIdsDetails.push(item);
      }

      setStakedIds(stakedIdsDetails);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllbadgePrices = async () => {
    try {
      const res = await getPrinterContract().getAllBadgePrices();
      setBadgePrices(res.map(badge => ethers.utils.formatEther(badge)));
    } catch (err) {
      console.log(err);
    }
  };
  const boostAPR = async () => {
    const checkForApproval = await getStayContract().allowance(account, printerAddress);
    if (parseInt(checkForApproval._hex) === 0) {
      let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      const getApproval = await getStayContract(true).approve(printerAddress, value);
      await getApproval.wait();
    }
    const boost = await getPrinterContract(true).boostLevel();
    await boost.wait();
    await getPrinterDetails();
  };

  // const stakeRocks = async quantity => {
  //   const checkForApproval = await getMyRocksContract().isApprovedForAll(account, printerAddress);
  //   if (!checkForApproval) {
  //     const getApproval = await getMyRocksContract().setApprovalForAll(printerAddress, true);
  //     await getApproval.wait();
  //   }
  //   const stayForApproval = await getStayContract().approve(account, printerAddress);
  //   if (parseInt(stayForApproval._hex) === 0) {
  //     let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
  //     const getApproval = await getStayContract().approve(printerAddress, value);
  //     await getApproval.wait();
  //   }
  //   const res = await getPrinterContract().stakeROCKs(quantity);
  //   await res.wait();
  //   await getPrinterDetails();
  //   await getPortfolioValues();
  // };

  const isApproveRocks = async () => {
    try {
      const checkForApproval = await getMyRocksContract().isApprovedForAll(account, printerAddress);
      return checkForApproval;
    } catch (err) {
      console.log(err);
    }
  };

  const approveRocks = async () => {
    const getApproval = await getMyRocksContract(true).setApprovalForAll(printerAddress, true);
    await getApproval.wait();
  };

  const IsapproveStayToPrinter = async () => {
    try {
      const checkForApproval = await getStayContract().allowance(account, printerAddress);
      if (parseInt(checkForApproval._hex) > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const IsapproveUsdcToPrinter = async () => {
    try {
      const checkForApproval = await getUSDCContract().allowance(account, printerAddress);
      if (parseInt(checkForApproval._hex) > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const approveStayToPrinter = async () => {
    let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    const getApproval = await getStayContract(true).approve(printerAddress, value);
    await getApproval.wait();
  };

  const approveUsdcToPrinter = async () => {
    let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    const getApproval = await getUSDCContract(true).approve(printerAddress, value);
    await getApproval.wait();
  };
  const stakeRocks = async quantity => {
    const res = await getPrinterContract(true).stakeROCKs(quantity);
    await res.wait();
    await getPrinterDetails();
    await getPortfolioValues();
  };

  const unstakeRocks = async quantity => {
    const res = await getPrinterContract(true).unstakeROCKs(quantity);
    await res.wait();
    await getPrinterDetails();
    await getPortfolioValues();
  };

  const unstakeStay = async amount => {
    const res = await getPrinterContract(true).unstakeStay(ethers.utils.parseEther(String(amount)));
    await res.wait();
    await getPrinterDetails();
    await getPortfolioValues();
  };

  const buyBadge = async (id, currency) => {
    let res;
    let conversion;

    if (currency === BNB) {
      let price = ethers.utils.parseEther(badgePrices[id - 1]);
      conversion = await getRouterContract().getAmountsIn(price, [wbnb, usdcAddress]);
      res = await getPrinterContract(true).buyBadge(id, BNB, {
        value: oldPrinterDetails.badges[id - 1] === id ? 0 : conversion[0],
      });
    } else {
      res = await getPrinterContract(true).buyBadge(id, currency);
    }

    await res.wait();
    await getPrinterDetails();
    await getPortfolioValues();
  };

  const printerClaimRewards = async () => {
    const res = await getPrinterContract(true).claimRewards();
    const tx = await res.wait();
    const events = await getPrinterContract().queryFilter("ClaimReward", tx.blockNumber);

    await getPrinterDetails();
    await getPortfolioValues();
    return ethers.utils.formatEther(events[0].args[1]._hex);
  };

  const getDicountedPrice = async () => {
    try {
      let _price = await getDiscountedContract()?.getPrice();
      _price = ethers.utils.formatEther(_price);
      setDiscountedPrice(_price);
    } catch (err) {
      console.log(err);
    }
  };

  const getItemsReminaing = async () => {
    try {
      let total = await getMyRocksContract().balanceOf(discountedNftAddress);
      setItemsRemaining(parseInt(total));
    } catch (err) {
      console.log(err);
    }
  };

  const checkUsdcTokenApprovalToDiscounted = async () => {
    try {
      const checkForApproval = await getUSDCContract().allowance(account, discountedNftAddress);

      if (parseInt(checkForApproval._hex) > 0) {
        setUsdcApprovalToDiscounted(true);
      } else {
        setUsdcApprovalToDiscounted(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      await Router_getStayPrice();
      await getAssetPrices();
      await getBoosterAmounts();
      await Farming_getTotalLpStaked();
      await Farming_RewardPerSecond();
    })();
  }, []);

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const getStayChart = async (duration = "24H") => {
    if (duration == "24H") {
      duration = 1;
    } else if (duration == "7D") {
      duration = 7;
    } else if (duration == "30D") {
      duration = 30;
    } else if (duration == "1Y") {
      duration = 365;
    } else {
      duration = 365;
    }

    const options = {
      method: "GET",
      headers: { accept: "application/json", "x-cg-demo-api-key": coinGeckoApi },
    };

    try {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/nfstay/market_chart?vs_currency=usd&days=${duration}&precision=4`,
        options,
      );
      const data = await response.json();
      const formattedData = data.prices.map(([timestamp, value]) => ({
        x: new Date(timestamp),
        y: value,
      }));

      setChartData(formattedData);
    } catch (error) {
      console.error(error);
    }
  };

  const getPercentChange = async () => {
    const options = {
      method: "GET",
      headers: { accept: "application/json", "x-cg-demo-api-key": coinGeckoApi },
    };

    try {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/nfstay?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`,
        options,
      );
      const data = await response.json();
      setPercentChange({
        price_change_24h: data?.market_data.price_change_24h,
        price_change_percentage_24h: data?.market_data.price_change_percentage_24h.toFixed(2),
        lineColor: data?.market_data.price_change_24h < 0 ? "#ff0000" : "#20E19F",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getOldPrinterDetails = async () => {
    try {
      const details = await getOldPrinterContract().getDetails(account);
      setOldPrinterDetails({
        currentLevel: Number(details.currentLevel),
        isBoosted: details.isBoosted,
        stakedNFTs: Number(details.stakedNFTs),
        isLock: Number(details.lockingPeriod) > Math.floor(Date.now() / 1000),
        lockingPeriod: Number(details.lockingPeriod) * 1000,
        stakedSTAY: Number(ethers.utils.formatEther(details.stakedSTAY)),
        pendingRewards: Number(ethers.utils.formatEther(details.pendingRewards)),
        badges: details.badges.map(badge => Number(badge)),
      });
      // await getStakedIdsPrinter();
      // await getPrinterHistory();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return (
    <NfstayContext.Provider
      value={{
        isMobile,
        stayPrice,
        Router_getStayPrice,
        getRouterContract,
        marketplaceNfts,
        referralDetails,
        porfolioDetails,
        loadListedItems,
        approveToMarketplace,
        buyItem,
        approveToken,
        approveswapperToken,
        stayApproval,
        usdcApproval,
        oldLpApproval,
        myusdApproval,
        cancelItem,
        updateItem,
        lpRate,
        stayRate,
        Swapper_swap,
        listItem,
        myNfts,
        Referral_buyNFT,
        USDCApprovaltoRocks,
        approveUSDCtoRocks,
        approveUSDCtoRouter,
        USDCApprovaltoRouter,
        // approval,
        checkForApproval,
        getbalance,
        checkTokenApprovalToZapper,
        Farming_stakeLps,
        approveToZapper,
        zapperApproval,
        zapper_swapping,
        farmingPendingShare,
        userDetails,
        Farming_ClaimRewards,
        Farming_Unstake,
        dashboardDetails,
        boostAPR,
        printerDetails,
        aprs,
        stakeRocks,
        unstakeRocks,
        unstakeStay,
        printerRewards,
        printerClaimRewards,
        approveRocks,
        approveStayToPrinter,
        isApproveRocks,
        IsapproveStayToPrinter,
        stakedIds,
        farmHistory,
        burnHistory,
        printerHistory,
        badgePrices,
        approveUsdcToPrinter,
        IsapproveUsdcToPrinter,
        getPrinterHistory,
        getBurnHistory,
        getFarmHistory,
        getBoughtItems,
        buyBadge,
        assetPrices,
        checkMyusdTokenApproval,
        checkoldLpApproval,
        checkUsdcTokenApprovaltoRocks,
        Farming_getUserInfo,
        getFarmingContract,
        getPrinterContract,
        setPrinterRewards,
        getPrinterDetails,
        getAllbadgePrices,
        getAssetPrices,
        loadMyNfts,
        getStakedIdsPrinter,
        getDashboardValues,
        getPortfolioValues,
        Referral_getDetails,
        Swapper_getStayRate,
        Swapper_getLpRate,
        checkUsdcTokenApprovaltoRouter,
        checkStayTokenApproval,
        checkUsdcTokenApproval,
        getMarketplaceContract,
        getAllOwnedNfts,
        Farming_getPendingShare,
        floorPrice,
        getFloorPrice,
        volume,
        marketSalesVolume,
        boosterAmounts,
        getLPContract,
        totalLpStaked,
        farmingRewardPerSecond,
        getDiscountedContract,
        usdcApprovalDiscounted,
        checkUsdcTokenApprovalToDiscounted,
        getMyRocksContract,
        itemsRemaining,
        discountedPrice,
        getItemsReminaing,
        getDicountedPrice,
        getUSDCContract,
        oldAssets,
        oldAssetBalance,
        getStayChart,
        chartData,
        getPercentChange,
        percentChange,
        getOldPrinterContract,
        getOldPrinterDetails,
        oldPrinterDetails,
      }}
    >
      {children}
    </NfstayContext.Provider>
  );
};
